import Vue from "vue"
import DatePicker from 'vue2-datepicker'
import SortedTablePlugin from "vue-sorted-table"
import Branches from '@/components/widgets/branches'
import Customers from '@/components/widgets/customers'
import AddItem from './components/add'
import Loading from '@/components/widgets/Loading.vue'
const _ = require('lodash')

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>'
})

export default {
  components: {
    DatePicker,
    Branches,
    'customers' :Customers,
    'add-item':AddItem,
    Loading
  },
  props:{
    item: Object
  },
  data() {
    return {
      perPage: 6,
      sortBy: 'name',
      currentPage: 1,
      sortDesc: false,
      sortable: true,
      source: '1',
      loading: false,
      product_opened: false,
      branch_opend: false,
      customer_opened: false,
      fields: [
        { 
          key: 'name', 
          label: 'ลูกค้า', 
          sortable: true, 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'td-top text-xs-1/2'
        },
        { 
          key: 'address', 
          label: 'ที่อยู่', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2'
        },
        { 
          key: 'contact', 
          label: 'ติดต่อ', 
          sortable: true, 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-left text-gray-400 td-top text-xs-1/2'
        },
        { 
          key: 'action', 
          label: '#', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
        }
      ],
      customer_items: [],
      product_fields: [
        { 
          key: 'description', 
          label: 'รายละเอียด', 
          sortable: true, 
          thClass: 'text-center font-weight-bold text-xs-1/2',
          tdClass: 'td-top text-xs-1/2'
        },
        { 
          key: 'price', 
          label: 'ราคา/หน่วย', 
          sortable: true, 
          thClass: 'text-center font-weight-bold text-xs-1/2',
          tdClass: 'text-right text-gray-400 td-top text-xs-1/2'
        },
        { 
          key: 'quantity', 
          label: 'จำนวน', 
          sortable: true, 
          thClass: 'text-center font-weight-bold text-xs-1/2',
          tdClass: 'text-right text-gray-400 td-top text-xs-1/2'
        },
        { 
          key: 'discount', 
          label: 'ส่วนลด', 
          sortable: true, 
          thClass: 'text-center font-weight-bold text-xs-1/2',
          tdClass: 'text-right text-gray-400 td-top text-xs-1/2'
        },
        { 
          key: 'action', 
          label: '#', 
          thClass: 'text-center font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
        }
      ],
      product_items: [],
      form: {
        uuid: null,
        products: '',
        customer: '',
        account_bank: '',
        account_name: '',
        account_number: ''
      }
    }
  },
  mounted() {
    this.$nextTick(async () => {
      if (this.$route.params.id) {
        try {
          this.loading = true
          this.form.uuid = this.$route.params.id
          const param = {
            _id: this.$route.params.id
          };
          const result = await this.$store.dispatch(
            "Documents/getInvoiceDocument",
            param,
            { root: true }
          )
          this.loading = false
          if (result?.data) {
            this.form.account_bank = result?.data.data.account_bank
            this.form.account_name = result?.data.data.account_name
            this.form.account_number = result?.data.data.account_number

            const customer = result.data?.data?.customer?.detail
            if (customer) {
              this.source = '2'
              this.customer_items.push(customer)
            } else {
              this.source = '1'
              const branch = result.data?.data?.branch_name
              this.customer_items.push(branch)
            }

            const pItems = result.data.products[0]
            for (let index = 0; index < pItems.detail.length; index++) {
              const element = pItems.detail[index]
              this.product_items.push(element)
            }
          }
        } catch (error) {
          this.loading = false
          const regexPattern = /(price)|(net_weight)+/g
          const matches = error.message.match(regexPattern)
          let message = null
          if (matches) {
            message = "ไม่ได้ระบุจำนวนเงินหรือยอดรวมสินค้ากรุณาตรวจสอบ!"
          }
          this.onExceptionHandler(message)        
        }
      }
    })
  },
  methods: {
    onopencustomer() {
      this.customer_opened = !this.customer_opened
    },
    onaftercustomer(e) {
      this.customer_opened = false
      this.source = '2'
      this.customer_items = []
      if (e.data) {
        this.customer_items.push(e.data)
      }      
    },
    onopenbranch() {
      this.branch_opend = !this.branch_opend
    },
    onafterbranch(e) {
      this.source = '1'
      this.branch_opend = false
      this.customer_items = []
      if (e.data) {
        this.customer_items.push(e.data)
      } 
    },
    onremove(uuid) {
      const index = _.findIndex(this.customer_items, function(o) { return o.uuid == uuid })
      this.customer_items.splice(index, 1)
    },
    onremove_product(index) {
      this.product_items.splice(index, 1)
    },
    onopenproduct() {
      this.product_opened = !this.product_opened
    },
    onafterproduct(e) {
      this.product_opened = false
      if (e.status) {
        this.product_items.push({
          description: e.data?.description,
          price: e.data?.price,
          quantity: e.data?.quantity,
          discount: e.data?.discount
        })
      }
    },
    previous() {
      this.$router.push('/invoice')
    },
    async onSubmitForm() {

      if (this.customer_items.length === 0) {
        this.onExceptionHandler("กรุณาเลือกข้อมูลลูกค้า")
        return false
      }

      if (this.source === '2' && (this.form.account_bank === '' || this.form.account_name === '' || this.form.account_number === '')) {
        this.onExceptionHandler("กรุณาใส่ข้อมูลบัญชีธนาคาร")
        return false
      }

      if (this.product_items.length === 0) {
        this.onExceptionHandler("กรุณาเลือกข้อมูลสินค้า")
        return false
      }

      this.form.products = JSON.stringify(this.product_items)
      this.form.customer = JSON.stringify(this.customer_items)
      try {
        this.loading = true
        const formData = new FormData()
        formData.append('account_bank', this.form.account_bank)
        formData.append('account_name', this.form.account_name)
        formData.append('account_number', this.form.account_number)
        formData.append('products', this.form.products)
        formData.append('customer', this.form.customer)

        let result = {}
        if (this.$route.params.id) {
          formData.append('source', this.source)
          result = await this.$store.dispatch('Invoice/update', {form: formData, Id: this.$route.params.id}, { root: true })
        } else {
          formData.append('source', this.source)
          result = await this.$store.dispatch('Invoice/create', formData, { root: true })
        }        
        
        this.loading = false    
        setTimeout(() => {
          if (result.status === 201 || result.status === 200) {
            this.$swal({
            width: "29rem",
            padding: '1.825em',
            html: '<div class="d-flex flex-row align-items-center">' +
              '<i class="fa fa-2x mr-3 fa-exclamation-triangle text-info"></i>'+
              '<div class="d-flex flex-column">'+
              '<span class="font-bold text-base py-2.5 text-left">แจ้งเตือน</span>'+
              `<span class="text-base text-left text-gray-500">บันทึกข้อมูลเรียบร้อยแล้ว</span>`+
              '</div>'+
            '</div>',
              allowOutsideClick: false,
              focusConfirm: false,
              customClass: {
                popup: 'swal2-custom-rounded',
                closeButton: 'text-3xl swal2-custom-close',
                actions: 'justify-content-end',
                contant: 'd-flex flex-row justify-content-around px-2',
                title: 'd-none',
                confirmButton: "btn btn-info py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
                cancelButton: 'btn btn-google py-3 rounded-lg text-white font-prompt-light text-sm font-normal'
              },
              showCancelButton: false,
              confirmButtonText: "ตกลง",
              cancelButtonText: "ยกเลิก",
              showCloseButton: true,
            }).then(result => {
              this.customer_items = []
              this.product_items = []
              this.form.products = ''
              this.form.customer = ''
              
              this.$router.push('/invoice')
            })
          }
        })
      } catch (error) {
        this.loading = false
        this.onExceptionHandler(error.message)
      }
    }
  }
}